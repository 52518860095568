import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Header from './Header';
import Footer from './Footer';
import {
  startGetOrderList
} from '../../actions/orders';

const MyAccountPage = ({ props, auth }) => {

  //const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadOrderList = async () => {
        //await props.startGetOrderList(props.auth.currentUser.uid);
        //setIsLoading(false);
    };

    loadOrderList();
  }, []);

  return (
    <div>
      <Header />
      <main className="main-content b-c-light-grey user-content-page">
        <div className="subheading bg-content bg-about">
          <div className="container">
            <h1>My Account<span className="subheading-border"></span></h1>
          </div>
        </div>
        <div className="container">
          <div>
            <p>Here are your account details, if there is anything here that needs amending then please contact us below and let us know what you would like to change.</p>
          </div>
          <div>
            <div className="account-details"><p><span className="title">User Name:</span> {auth.currentUser.name}</p></div>
            <div className="account-details"><p><span className="title">Contact Name:</span> {auth.currentUser.contactname}</p></div>
            <div className="account-details"><p><span className="title">Contact Number:</span> {auth.currentUser.telephone}</p></div>
            <div className="account-details"><p><span className="title">Email:</span> {auth.currentUser.email}</p></div>            
            <div className="account-details"><p><span className="title">Address:</span><br></br> {auth.currentUser.address.split(',').map(line => <span key={line}>{line},<br/></span>)}</p></div>
            {/*
            <Button
              variant="contained"
              color="primary"
              className="button m-t-50 hidden"
            >
            Order History
            
            </Button>
            */}
          </div>

          {/* {isLoading ? <p>Loading</p> : props.userorders ? <div>{props.userorders.id}</div> : <p>Here</p>} */}

          <div className="text-center m-t-50">
            <a className="button-mail" href="mailto:paulcfarndell@gmail.com?subject=Change Account Details" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                color="primary"
                className="button-primary button-large add-cart-button"
              >
                Change Account Details
              </Button>
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userorders: state.userorder
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startGetOrderList: (id) => {
      dispatch(startGetOrderList(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(MyAccountPage);