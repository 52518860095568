import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Header from './Header';
import Footer from './Footer';

const NewProductPage = () => {
  return (
    <div>
      <Header />
      <main className="main-content b-c-light-grey user-content-page">
        <div className="subheading bg-content bg-newproduct">
          <div className="container">
            <h1>New Product<span className="subheading-border"></span></h1>
          </div>
        </div>
        <div className="container">
          <div className="b-c-white text-center">
            <h2>Are you looking to add a new product to your collection?</h2>
            <p>If there is a product that isn't currently listed, simply follow these steps below.</p>
            <div className="steps-container">
              <div className="steps-item">
                <div className="steps-number">1</div>
                <div className="steps-icon"><ImportContactsIcon fontSize="large" /></div>
                <div className="steps-text">
                  <p>Find the details of the product - title, description, image and PDF if possible.</p>
                </div>
              </div>
              <div className="steps-item">
                <div className="steps-number">2</div>
                <div className="steps-icon"><MailOutlineIcon fontSize="large" /></div>
                <div className="steps-text">
                  <p>Click the button below to send us an email with all the info provided.</p>
                </div>
              </div>
              <div className="steps-item">
                <div className="steps-number">3</div>
                <div className="steps-icon"><ThumbUpIcon fontSize="large" /></div>
                <div className="steps-text">
                  <p>Once we have recieved your email we'll add the new product and let you know when its available to add to your cart!</p>
                </div>
              </div>
            </div>
            <div className="add-prod-arrow">
              <ArrowDownwardIcon className="bounce" fontSize="large" />
            </div>            
            <a className="button-mail" href="mailto:paulcfarndell@gmail.com?subject=Add New Product" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                color="primary"
                className="button-primary button-large add-cart-button"
              >
                Add new Product
              </Button>
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default NewProductPage;