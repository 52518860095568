import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Header from './Header';
import Footer from './Footer';

const ContactPage = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <Header />
            <main className="main-content b-c-light-grey user-content-page">
                <div className="subheading bg-content bg-contact-us">
                    <div className="container">
                        <h1>Contact Us<span className="subheading-border"></span></h1>                        
                    </div>
                </div>
                <div className="container">
                    <div className="b-c-white">
                        <p>If you have any questions or you're having an issue, please check out our FAQs below. If none of these relate to your question feel free to contact us via the button at the bottom of the page.</p>
                        <div className="faq-container">
                            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography className="faq-heading">How do I place an order?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <p><Link to="/how-does-this-work" className="faq-link">Placing an order is simple</Link>. Find the product you want, select the quantity of the product and then add to your cart. Then you can process the order on the cart page!</p>
                                </ExpansionPanelDetails>                                                    

                            </ExpansionPanel>
                            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography className="faq-heading">I can't find the product I want, what do I do?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <p>If you havent been able to find the product you want, <Link to="/new-product" className="faq-link">get in touch!</Link> We'll get the product added as soon as possible.</p>
                                </ExpansionPanelDetails>                                                    

                            </ExpansionPanel>
                            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography className="faq-heading">How quickly will I recieve my order?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <p>Delivery will take approx 5 working days</p>
                                </ExpansionPanelDetails>                                                    

                            </ExpansionPanel>                         
                            <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6-content"
                                    id="panel6-header"
                                >
                                    <Typography className="faq-heading">Can I change my account details?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <p>Click on the contact us bottom below to let us know of any changes</p>
                                </ExpansionPanelDetails>                                                    

                            </ExpansionPanel>                         
                        </div>                        
                    </div>
                    <div className="text-center">
                        <p>If you didn't find the answer you were looking for then please contact us below</p>
                        <div className="add-prod-arrow">
                            <ArrowDownwardIcon className="bounce" fontSize="large" />
                        </div>
                        <a className="button-mail" href="mailto:paulcfarndell@gmail.com?subject=Contact Us" target="_blank" rel="noopener noreferrer">
                            <Button
                            variant="contained"
                            color="primary"
                            className="button-primary button-large add-cart-button"
                            >
                            Contact Us
                            </Button>
                        </a>
                    </div>
                </div>
            </main>
            <Footer />            
        </div>
    );
}

export default ContactPage;